import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { ReactReduxContext, useDispatch } from "react-redux";
import { IconButton, Switch } from '@mui/material';
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { SharedStateProvider } from "../SharedStateContext";
import { exchangeProductSettingGet } from "../../../actions/exchangeAction";
import { saveOptionStrategyThreeData } from "../../../actions/optionStrategyThreeAction";
import { getSymbolExpirys } from "../../../actions/optionStrategyOneAction";
import { getAllSymbols } from "../../../actions/optionStrategyCommanAction";
import { toast } from "react-toastify";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';


function OptionStrategyThreeAdd() {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    // reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      account: "",
      symbol: "",
      expiry_date: "",
      formData: [{ switchType: false, buySellSwitchType: false, start_value: '', condition: '', value: '', quantity: '' }]
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "formData"
  });

  const [dropdownList, setDropdownList] = useState({ setting: [] });
  const [expiryData, setExpiryData] = useState({});
  const [selectedDay, setSelectedDay] = useState([])
  const [expiry, setExpiry] = useState();
  const [strategySymbols, setStrategySymbols] = useState([]);
  const [symbols, setAllSymbols] = useState([]);
  const [accountName, setAccountName] = useState();

  const navigate = useNavigate();

  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch();

  const handleDayChange = (day) => {
    if (selectedDay.includes(day)) {
      setSelectedDay(selectedDay.filter(x => x !== day))
    }
    else {
      setSelectedDay([...selectedDay, day])
    }
  }

  const onSubmit = async (data) => {
    const selectedAccount = data.account.split("|");
    const submissionData = {
      ...data,
      account_id: selectedAccount[0],
      account_name: selectedAccount[1],
    };
    const selectedSymbol = submissionData?.symbol?.split("|")

    submissionData["runningDays"] = selectedDay
    submissionData["companyName"] = selectedSymbol[0]
    submissionData["symbol"] = selectedSymbol[1]
    submissionData["exchange"] = selectedSymbol[2]
    submissionData["broker"] = accountName
    await saveOptionStrategyThreeData(dispatch, submissionData)
      .then((res) => {
        navigate("/option-strategy-three")
        toast.success("Strategy data successfully saved in json file");
      })
      .catch((error) => {
        console.error("Error saving data", error);
      });
  };

  const getSettingData = async () => {
    await exchangeProductSettingGet(dispatch);
    if (store.getState().exchange?.exchangeProductSettingList) {
      const res = store.getState().exchange?.exchangeProductSettingList;
      if (res) {
        setDropdownList(res?.data);
      }
    }
  };

  const handleExpiry = async () => {
    if (!expiry && Object.keys(expiryData).length >= 2) {
      await getSymbolExpirys(dispatch, expiryData).then((res) => {
        setExpiry(res);
      }).catch((error) => { });
    }
  };

  const handleSymbol = (event) => {
    const symbolDetail = event.target.value?.split("|");
    const symbol_company_name = symbolDetail[0]
    const symbol_name = symbolDetail[1]
    const symbol_exchange = symbolDetail[2]

    if (symbol_name && symbol_exchange) {
      setExpiryData({ ...expiryData, "symbol": symbol_name, "companyName": symbol_company_name, "exchange_segment": symbol_exchange });
      setExpiry(null)
    }
  };

  function getValueByKey(key) {
    for (const obj of dropdownList?.setting) {
      const values = Object.values(obj);
      for (const value of values) {
        if (key in value) {
          return value[key];
        }
      }
    }
    return null; // Return null or any default value if the key is not found
  }


  const handleAccount = (event) => {
    const accountId = event.target.value.split("|")[0];
    const accountName = event.target.value.split("|")[1];

    setAccountName(getValueByKey(accountName))
    setExpiryData({ ...expiryData, "account_id": accountId });
    setValue("symbol", "")
    const filteredSymbol = symbols.filter(item => item.account_name === accountName);
    setStrategySymbols(filteredSymbol)
  };

  const handleFormField = () => {
    append({
      switchType: false,
      buySellSwitchType: false,
      start_value: '',
      conditions: '',
      value: '',
      quantity: ''
    });
  };

  const getSymbolsData = async () => {
    await getAllSymbols(dispatch).then((res) => {
      setAllSymbols(res?.data?.symbols || [])
    }).catch((error) => {
      toast.error("Symbols list not found")
    })
  };

  useEffect(() => {
    getSettingData();
    getSymbolsData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fields.forEach((item, index) => {
      const condition = watch(`formData[${index}].condition`);

      if (condition === "CP") {
        setValue(`formData[${index}].value`, "");
      }
    });
  }, [fields, watch, setValue]);

  return (
    <div className="Main">
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <section className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="page-header">
                <div className="page-block">
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <ul className="breadcrumb p-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/option-strategy-three" style={{ fontWeight: 600 }}>
                            OTM Strategy
                          </Link>
                        </li>
                      </ul>
                      <div className="page-header-title d-flex justify-content-between pb-3">
                        <div className="ListWithBadge">
                          <h3 className="m-b-20 mr-2">Add New Strategy</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="main-body">
                <div className="page-wrapper">
                  <div className="card">
                    <div className="card-body pt-3 pb-1">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row col-md-12 pr-0">
                          <div className="form-group col-md-3 pr-0">
                            <label>Account*</label>
                            <select
                              className="form-control"
                              {...register("account", { required: true })}
                              defaultValue=""
                              onChange={handleAccount}
                            >
                              <option value="" disabled>Select...</option>
                              {dropdownList?.setting?.map((setting) => {
                                const id = Object.keys(setting)[0];
                                const nestedObject = setting[id];
                                const key = Object.keys(nestedObject)[0];

                                return (
                                  <option key={id} value={`${id}|${key}`}>
                                    {key}
                                  </option>
                                );
                              })}
                            </select>
                            {errors.account && (
                              <span className="validationAlert">Account is required</span>
                            )}
                          </div>
                          <div className="form-group col-md-3 pr-0">
                            <label>Symbol*</label>
                            <select
                              className="form-control"
                              {...register("symbol", { required: true })}
                              defaultValue=""
                              onChange={handleSymbol}
                            >
                              <option value="" disabled>Select...</option>
                              {strategySymbols?.map((data, index) => {
                                return accountName === "ANGEL" ? (
                                  <option value={`${data?.companyName}|${data?.shortName}|${data?.marketType}`} key={index}>{data?.companyName}</option>
                                ) : (
                                  <option value={`${data?.companyName}|${data?.shortName}|${data?.marketType}`} key={index}>{data?.shortName}</option>
                                )
                              })}
                            </select>
                            {errors.symbol && (
                              <span className="validationAlert">Symbol is required</span>
                            )}
                          </div>
                          <div className="form-group col-md-3 pr-0">
                            <label>Expiry date*</label>
                            <Controller
                              name="expiry_date"
                              control={control}
                              defaultValue=""
                              {...register("expiry_date", { required: true })}
                              render={({ field }) => (
                                <select
                                  className="form-control"
                                  {...field}
                                  onClick={handleExpiry}
                                >
                                  <option value="" disabled>Select...</option>
                                  {expiry?.map((data, index) => (
                                    <option key={index} value={data}>{data}</option>
                                  ))}
                                </select>
                              )}
                            />
                            {errors.expiry_date && (
                              <span className="validationAlert">Expiry date is required</span>
                            )}
                          </div>
                          <div className="form-group col-md-3 pr-0">
                            <label>Running day*</label>
                            <div className="multi-select-tab-wrapper">
                              {
                                ["Mon", "Tue", "Wed", "Thu", "Fri"].map(x => {
                                  return <div className={`tab ${selectedDay.includes(x) ? "tab-active" : ""}`} onClick={() => handleDayChange(x)} >
                                    {x}
                                  </div>
                                })
                              }
                            </div>
                          </div>
                        </div>

                        <div className="row col-md-12 mb-4 pr-0">
                          <div className="form-group col-md-2 pr-0">
                            <label>Overall Profit</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Overall Profit"
                              {...register(`overall_profit`, {
                                required: false,
                              })}
                              onKeyPress={(e) => {
                                if (e.key === '-' || e.key === '.' || e.key === 'e') {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {errors?.overall_profit && (
                              <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                {errors?.overall_profit?.message}
                              </div>
                            )}
                          </div>
                          <div className="form-group col-md-2 pr-0">
                            <label>Overall Loss</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Overall Loss"
                              {...register(`overall_loss`, {
                                required: false,
                              })}
                              onKeyPress={(e) => {
                                if (e.key === '-' || e.key === '.' || e.key === 'e') {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {errors?.overall_loss && (
                              <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                {errors?.overall_loss?.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <hr />

                        {fields.map((item, index) => {
                          const condition = watch(`formData[${index}].condition`);
                          const handleConditionChange = (event) => {
                            const selectedCondition = event.target.value;
                            setValue(`formData[${index}].condition`, selectedCondition);

                            // Clear the value field immediately when "CP" is selected
                            if (selectedCondition === "CP") {
                              setValue(`formData[${index}].value`, "");
                            }
                          };

                          return (
                            <div className="row col-md-12 mb-4 pr-0" key={item.id}>
                              <div className="form-group col-md-2 pr-0" style={{ margin: "auto" }}>
                                <label>Call</label>
                                <Switch
                                  id={`switchType_${index}`}
                                  name={`formData[${index}].switchType`}
                                  color="default"
                                  {...register(`formData[${index}].switchType`)}
                                  checked={watch(`formData[${index}].switchType`)}
                                  onChange={(e) => setValue(`formData[${index}].switchType`, e.target.checked)}
                                  className="switchStyle"
                                />
                                <label>Put</label>
                              </div>
                              <div className="form-group col-md-2 pr-0" style={{ margin: "auto" }}>
                                <label>Buy</label>
                                <Switch
                                  id={`buySellSwitchType_${index}`}
                                  name={`formData[${index}].buySellSwitchType`}
                                  color="default"
                                  {...register(`formData[${index}].buySellSwitchType`)}
                                  checked={watch(`formData[${index}].buySellSwitchType`)}
                                  onChange={(e) => setValue(`formData[${index}].buySellSwitchType`, e.target.checked)}
                                  className="switchStyle"
                                />
                                <label>Sell</label>
                              </div>
                              <div className="form-group col-md-1 pr-0">
                                <label>Start At*</label>
                                <input
                                  id={`start_value_${index}`}
                                  className="form-control"
                                  placeholder="Value"
                                  {...register(`formData[${index}].start_value`, { required: "Start value is required" })}
                                />
                                {errors?.formData && errors.formData[index]?.start_value && (
                                  <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                    {errors.formData[index].start_value.message}
                                  </div>
                                )}
                              </div>

                              <div className="form-group col-md-2 pr-0">
                                <label>Condition*</label>
                                <select
                                  id={`condition_${index}`}
                                  className="form-control"
                                  {...register(`formData[${index}].condition`, { required: true })}
                                  defaultValue=""
                                  onChange={handleConditionChange}
                                >
                                  <option value="" disabled>Select...</option>
                                  <option value="CP">{"CP"}</option>
                                  <option value="CP <=">{"CP <="}</option>
                                  <option value="CP >=">{"CP >="}</option>
                                </select>
                                {errors?.formData && errors.formData[index]?.condition && (
                                  <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                    {errors.formData[index].condition.message}
                                  </div>
                                )}
                              </div>
                              <div className="form-group col-md-1 pr-0">
                                <label>End of*</label>
                                <input
                                  id={`value_${index}`}
                                  className="form-control"
                                  placeholder="Value"
                                  disabled={condition === "CP"}
                                  {...register(`formData[${index}].value`, { required: false })}
                                />
                                {errors?.formData && errors.formData[index]?.value && (
                                  <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                    {errors.formData[index].value.message}
                                  </div>
                                )}
                              </div>
                              <div className="form-group col-md-2 pr-0">
                                <label>Quantity*</label>
                                <input
                                  id={`quantity_${index}`}
                                  className="form-control"
                                  placeholder="Quantity"
                                  {...register(`formData[${index}].quantity`, { required: "Quantity is required" })}
                                />
                                {errors?.formData && errors.formData[index]?.quantity && (
                                  <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                    {errors.formData[index].quantity.message}
                                  </div>
                                )}
                              </div>
                              <div className="form-group col-md-1 pr-0 d-flex align-items-center mt-4">
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => remove(index)}
                                  color="error"
                                  className="mr-3"
                                >
                                  <DeleteIcon />
                                </IconButton>
                                {index === fields?.length - 1 ?
                                  <IconButton
                                    aria-label="add"
                                    onClick={handleFormField}
                                    color="primary"
                                  >
                                    <AddIcon />
                                  </IconButton>

                                  : null}
                              </div>
                              <div>

                              </div>
                            </div>
                          )
                        })}


                        <div className="mt-2 text-center">
                          <button
                            type="submit"
                            className="btn btn-primary ml-3 px-4"
                          >
                            Save & Continue
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default OptionStrategyThreeAdd;
