// BASIC IMPORTS
import React, { useState, useMemo, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { ReactReduxContext, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import PropTypes from "prop-types";

// MUI IMPORTS
import { Box, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Typography, Paper, IconButton, Tooltip, Checkbox } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import DeleteIcon from "@mui/icons-material/Delete";

// FILE IMPORTS
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { SharedStateProvider } from "../SharedStateContext";
import { getDeletedEmaStrategies, hardDeleteStrategy } from "../../../actions/emaStrategyAction";
import DataNotFound from "../../layout/DataNotFound";



function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const data = comparator(a[0], b[0]);
        if (data !== 0) {
            return data;
        }
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
    {
        id: "account",
        numeric: false,
        disablePadding: false,
        label: "Account",
    },
    {
        id: "symbol",
        numeric: false,
        disablePadding: false,
        label: "Symbol",
    },
    {
        id: "type",
        numeric: false,
        disablePadding: false,
        label: "Type",
    },
    {
        id: "interval",
        numeric: true,
        disablePadding: false,
        label: "Interval",
    },
    {
        id: "upper EMA",
        numeric: true,
        disablePadding: false,
        label: "upper_ema",
    },
    {
        id: "Lower EMA",
        numeric: true,
        disablePadding: false,
        label: "lower_ema",
    }
];

function EnhancedTableHead(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        onRequestSort,
        rowCount,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            "aria-label": "select all desserts",
                        }}
                    />
                </TableCell>
                {headCells.map((headCell, index) => (
                    <TableCell
                        key={index}
                        padding={headCell?.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell?.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell?.id}
                            direction={orderBy === headCell?.id ? order : "asc"}
                            onClick={createSortHandler(headCell?.id)}
                        >
                            {headCell?.label}
                            {orderBy === headCell?.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EmaDeletedStrategy() {
    const {
        formState: { errors },
        control,
        watch,
        reset,
    } = useForm({
        defaultValues: {},
    });

    const { store } = useContext(ReactReduxContext);
    const dispatch = useDispatch();

    const [selected, setSelected] = useState([]);
    // const [filterSymbol, filterSymbolList] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("accountName");
    const [isOpen, setIsopen] = useState(false);
    const [strategy, setStrategy] = useState([]);


    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const visibleRows = useMemo(
        () =>
            stableSort(strategy, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
            ),
        [order, orderBy, page, rowsPerPage, strategy]
    );

    const ToggleSidebar = () => {
        reset();
        isOpen === true ? setIsopen(false) : setIsopen(true);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected?.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected?.concat(selected?.slice(1));
        } else if (selectedIndex === selected?.length - 1) {
            newSelected = newSelected?.concat(selected?.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected?.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = strategy?.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleMultiDelete = async () => {
        if (selected?.length !== 0) {
        //     // delte multiple records
            Swal.fire({
                title: "Are you sure?",
                text: `Are you sure you want to delete the selected ${selected?.length} record?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await hardDeleteStrategy(selected).then((res) => {
                        toast.success("Strategy deleted successfully!");
                        fetchData();
                        setSelected([])
                        setPage(0);
                    }).catch((error) => {
                        toast.error("Strategy not able delete due to some error")
                    })
                }
            });
        }
    };

    const fetchData = async () => {
        await getDeletedEmaStrategies(dispatch).then((res) => {
            setStrategy(res?.deleted_strategies)
        }).catch((error) => {})
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div className="Main">
            <div
                className={`sidebar-overlay ${isOpen === true ? "active" : ""}`}
                onClick={ToggleSidebar}
            ></div>
            <SharedStateProvider>
                <SideBar />
                <Header />
            </SharedStateProvider>
            <div className="pcoded-main-container">
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content"></div>
                        <div className="main-body">
                            <div className="page-wrapper">
                                <div className="row align-items-center">
                                    <div className="col-md-12">
                                        <ul className="breadcrumb p-0">
                                            <li className="breadcrumb-item">
                                                <Link to="/dashboard">Dashboard</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <Link to="/ema-strategy">EMA Strategy</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <Link to="/ema-delete-strategy" style={{ fontWeight: 600 }}>
                                                    Delete Strategy
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <Box sx={{ width: "100%" }}>
                                            {visibleRows?.length !== 0 ? (
                                                <Paper
                                                    sx={{ width: "100%", mb: 2 }}
                                                    className="customTable"
                                                >
                                                    <Toolbar
                                                        sx={{
                                                            pl: { sm: 2 },
                                                            pr: { xs: 1, sm: 1 },
                                                            ...(selected?.length > 0 && {
                                                                bgcolor: (theme) =>
                                                                    alpha(
                                                                        theme.palette.primary.main,
                                                                        theme.palette.action.activatedOpacity
                                                                    ),
                                                            }),
                                                        }}
                                                        className="p-1"
                                                    >
                                                        {selected?.length > 0 ? (
                                                            <Typography
                                                                sx={{ flex: "1 1 100%" }}
                                                                color="inherit"
                                                                variant="subtitle1"
                                                                component="div"
                                                            >
                                                                {selected?.length} selected
                                                            </Typography>
                                                        ) : (
                                                            <Typography
                                                                sx={{ flex: "1 1 100%" }}
                                                                variant="h6"
                                                                id="tableTitle"
                                                                component="div"
                                                            >
                                                                Deleted Strategy
                                                            </Typography>
                                                        )}

                                                        {selected?.length > 0 ? (
                                                            <Tooltip title="Delete">
                                                                <IconButton onClick={handleMultiDelete}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        ) : (
                                                            <i className="feather icon-trending-up pr-3"></i>
                                                        )}
                                                    </Toolbar>
                                                    <TableContainer>
                                                        <Table
                                                            className="addTableFontColor"
                                                            sx={{ minWidth: 750 }}
                                                            aria-labelledby="tableTitle"
                                                        >
                                                            <EnhancedTableHead
                                                                onSelectAllClick={handleSelectAllClick}
                                                                order={order}
                                                                orderBy={orderBy}
                                                                numSelected={selected?.length}
                                                                onRequestSort={handleRequestSort}
                                                                rowCount={strategy?.length}
                                                            />

                                                            <TableBody>
                                                                {visibleRows?.map((row, index) => {
                                                                    const isItemSelected = isSelected(row?.id);
                                                                    const labelId = `enhanced-table-checkbox-${index}`;
                                                                    return (
                                                                        <TableRow
                                                                            hover
                                                                            role="checkbox"
                                                                            aria-checked={isItemSelected}
                                                                            tabIndex={-1}
                                                                            key={row?.id}
                                                                            selected={isItemSelected}
                                                                            sx={{ cursor: "pointer" }}
                                                                        >
                                                                            <TableCell padding="checkbox">
                                                                                <Checkbox
                                                                                    onClick={(event) =>
                                                                                        handleClick(event, row?.id)
                                                                                    }
                                                                                    color="primary"
                                                                                    checked={isItemSelected}
                                                                                    inputProps={{
                                                                                        "aria-labelledby": labelId,
                                                                                    }}
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell>{row?.account}</TableCell>
                                                                            <TableCell>{row?.symbol}</TableCell>
                                                                            <TableCell>{row?.type}</TableCell>
                                                                            <TableCell>{row?.interval}</TableCell>
                                                                            <TableCell>{row?.upper_ema}</TableCell>
                                                                            <TableCell>{row?.lower_ema}</TableCell>
                                                                        </TableRow>
                                                                    );
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                    <TablePagination
                                                        rowsPerPageOptions={[5, 10, 25]}
                                                        component="div"
                                                        count={strategy?.length}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                    />
                                                </Paper>
                                            ) : <DataNotFound btn_text={"Back to EMA Strategy"} btn_path={"/ema-strategy"} />}
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmaDeletedStrategy;
