import axiosClient from "../axios-client";


export const createSymbol = async (dispatch, symbol) => {
    try {
        const res = await axiosClient.post(
            `/option_strategy/create_symbol`,
            symbol
        );
        return res;
    } catch (error) {
        throw error;
    } finally {
    }
};

export const getAllSymbols = async (dispatch) => {
    try {
        const res = await axiosClient.get(`/option_strategy/get_all_symbols/`);
        return res;
    } catch (error) {
        throw error;
    } finally {
    }
};

export const getSymbolById = async (dispatch, symbol_id) => {
    try {
        const res = await axiosClient.get(`/option_strategy/symbol/${symbol_id}`);
        return res;
    } catch (error) {
        throw error;
    } finally {
    }
};

export const updateSymbol = async (dispatch, symbol_id, data) => {
    try {
        const res = await axiosClient.put(
            `/option_strategy/symbol/${symbol_id}`,
            data
        );
        return res;
    } catch (error) {
        throw error;
    } finally {
    }
};

export const deleteSymbol = async (dispatch, data) => {
    try {
        const res = await axiosClient.post(`/option_strategy/symbols`, data);
        return res;
    } catch (error) {
        throw error;
    } finally {
    }
};
