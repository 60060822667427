import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { ReactReduxContext, useDispatch } from "react-redux";
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { SharedStateProvider } from "../SharedStateContext";
import { exchangeProductSettingGet } from "../../../actions/exchangeAction";
import { getSymbolExpirys } from "../../../actions/optionStrategyOneAction";
import { getAllSymbols } from "../../../actions/optionStrategyCommanAction";
import { toast } from "react-toastify";
import { saveRenkoEmaStrategy } from "../../../actions/renkoEmaStrategyAction";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


function RenkoEMAStrategyAdd() {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  const [dropdownList, setDropdownList] = useState({ setting: [] });
  const [expiryData, setExpiryData] = useState({});
  const [selectedDay, setSelectedDay] = useState([])
  const [expiry, setExpiry] = useState();
  const [strategySymbols, setStrategySymbols] = useState([]);
  const [symbols, setAllSymbols] = useState([]);
  const [accountName, setAccountName] = useState();
  const [segment, setSegment] = useState('option');
  const [selectedSide, setSelectedSide] = useState("");


  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDayChange = (day) => {
    if (selectedDay.includes(day)) {
      setSelectedDay(selectedDay.filter(x => x !== day))
    }
    else {
      setSelectedDay([...selectedDay, day])
    }
  }

  const handleSideChange = (event) => {
    setSelectedSide(event.target.value);
    setValue("call_variation", "")
    setValue("put_variation", "")
  };

  const handleSegment = (event, newAlignment) => {
    if(newAlignment){
      setSegment(newAlignment);
      setExpiryData({})
      setExpiry([])
      setStrategySymbols([])
      setAccountName()
      reset()
    }
  };

  const onSubmit = async (data) => {
    const selectedAccount = data.account.split("|");
    const submissionData = {
      ...data,
      account_id: selectedAccount[0],
      account_name: selectedAccount[1],
    };
    const selectedSymbol = submissionData?.symbol?.split("|")
    delete submissionData["account"]
    submissionData["runningDays"] = selectedDay
    submissionData["companyName"] = selectedSymbol[0]
    submissionData["symbol"] = selectedSymbol[1]
    submissionData["exchange"] = selectedSymbol[2]
    submissionData["broker"] = accountName
    submissionData["is_delete"] = false
    submissionData["segment"] = segment

    await saveRenkoEmaStrategy(dispatch, submissionData)
      .then((res) => {
        navigate("/renko-ema-strategy")
        toast.success("Strategy data successfully saved in json file");
      })
      .catch((error) => {
        console.error("Error saving data", error);
      });
  };

  const getSettingData = async () => {
    await exchangeProductSettingGet(dispatch);
    if (store.getState().exchange?.exchangeProductSettingList) {
      const res = store.getState().exchange?.exchangeProductSettingList;
      if (res) {
        setDropdownList(res?.data);
      }
    }
  };

  const handleExpiry = async () => {
    if (!expiry && Object.keys(expiryData).length >= 2) {
      await getSymbolExpirys(dispatch, expiryData).then((res) => {
        setExpiry(res);
      }).catch((error) => { });
    }
  };

  const handleSymbol = (event) => {
    const symbolDetail = event.target.value?.split("|");
    const symbol_company_name = symbolDetail[0]
    const symbol_name = symbolDetail[1]
    const symbol_exchange = symbolDetail[2]

    if (symbol_name && symbol_exchange) {
      setExpiryData({ ...expiryData, "symbol": symbol_name, "companyName": symbol_company_name, "exchange_segment": symbol_exchange, "segment": segment });
      setExpiry(null)
    }
  };

  function getValueByKey(key) {
    for (const obj of dropdownList?.setting) {
      const values = Object.values(obj);
      for (const value of values) {
        if (key in value) {
          return value[key];
        }
      }
    }
    return null; // Return null or any default value if the key is not found
  }


  const handleAccount = (event) => {
    const accountId = event.target.value.split("|")[0];
    const accountName = event.target.value.split("|")[1];

    setAccountName(getValueByKey(accountName))
    setExpiryData({ ...expiryData, "account_id": accountId });
    setValue("symbol", "")
    const filteredSymbol = symbols.filter(item => item.account_name === accountName);
    setStrategySymbols(filteredSymbol)
  };

  const getSymbolsData = async () => {
    await getAllSymbols(dispatch).then((res) => {
      setAllSymbols(res?.data?.symbols || [])
    }).catch((error) => {
      toast.error("Symbols list not found")
    })
  };

  useEffect(() => {
    getSettingData();
    getSymbolsData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="Main">
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <section className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="page-header">
                <div className="page-block">
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <ul className="breadcrumb p-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/renko-ema-strategy">
                            Renko EMA Strategy
                          </Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/renko-ema-strategy/create" style={{ fontWeight: 600 }}>
                            Create
                          </Link>
                        </li>
                      </ul>
                      <div className="page-header-title d-flex justify-content-between pb-3">
                        <div className="ListWithBadge">
                          <h3 className="m-b-20 mr-2">Add New Strategy</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="main-body">
                <div className="page-wrapper">
                  <div className="card">
                    <div className="card-body pt-3 pb-1">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="text-center mb-4">
                          <ToggleButtonGroup
                            color="primary"
                            value={segment}
                            exclusive
                            onChange={handleSegment}
                            aria-label="Segment"
                          >
                            <ToggleButton value="option">Option</ToggleButton>
                            <ToggleButton value="future">Future</ToggleButton>
                          </ToggleButtonGroup>
                        </div>
                        <div className="row col-md-12 pr-0">
                          <div className="form-group col-md-3 pr-0">
                            <label>Account*</label>
                            <select
                              className="form-control"
                              {...register("account", { required: true })}
                              defaultValue=""
                              onChange={handleAccount}
                            >
                              <option value="" disabled>Select...</option>
                              {dropdownList?.setting?.map((setting) => {
                                const id = Object.keys(setting)[0];
                                const nestedObject = setting[id];
                                const key = Object.keys(nestedObject)[0];

                                return (
                                  <option key={id} value={`${id}|${key}`}>
                                    {key}
                                  </option>
                                );
                              })}
                            </select>
                            {errors.account && (
                              <span className="validationAlert">Account is required</span>
                            )}
                          </div>
                          <div className="form-group col-md-3 pr-0">
                            <label>Symbol*</label>
                            <select
                              className="form-control"
                              {...register("symbol", { required: true })}
                              defaultValue=""
                              onChange={handleSymbol}
                            >
                              <option value="" disabled>Select...</option>
                              {strategySymbols?.map((data, index) => {
                                return accountName === "ANGEL" ? (
                                  <option value={`${data?.companyName}|${data?.shortName}|${data?.marketType}`} key={index}>{data?.companyName}</option>
                                ) : (
                                  <option value={`${data?.companyName}|${data?.shortName}|${data?.marketType}`} key={index}>{data?.shortName}</option>
                                )
                              })}
                            </select>
                            {errors.symbol && (
                              <span className="validationAlert">Symbol is required</span>
                            )}
                          </div>
                          <div className="form-group col-md-3 pr-0">
                            <label>Expiry date*</label>
                            <Controller
                              name="expiry_date"
                              control={control}
                              defaultValue=""
                              {...register("expiry_date", { required: true })}
                              render={({ field }) => (
                                <select
                                  className="form-control"
                                  {...field}
                                  onClick={handleExpiry}
                                >
                                  <option value="" disabled>Select...</option>
                                  {expiry?.map((data, index) => (
                                    <option key={index} value={data}>{data}</option>
                                  ))}
                                </select>
                              )}
                            />
                            {errors.expiry_date && (
                              <span className="validationAlert">Expiry date is required</span>
                            )}
                          </div>
                          <div className="form-group col-md-3 pr-0">
                            <label>Running day</label>
                            <div className="multi-select-tab-wrapper">
                              {
                                ["Mon", "Tue", "Wed", "Thu", "Fri"].map(x => {
                                  return <div className={`tab ${selectedDay.includes(x) ? "tab-active" : ""}`} onClick={() => handleDayChange(x)} >
                                    {x}
                                  </div>
                                })
                              }
                            </div>
                          </div>
                        </div>

                        <div className="row col-md-12 pr-0">
                          <div className="form-group col-md-2 pr-0">
                            <label>Lower EMA Value*</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Lower ema value"
                              {...register(`lower_ema_value`, {
                                required: "Lower ema value is required",
                                valueAsNumber: true, // Converts the input to a number
                                validate: (value) => Number.isInteger(value) || "Only integers are allowed"
                              })}
                              onKeyPress={(e) => {
                                if (e.key === '-' || e.key === '.' || e.key === 'e') {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {errors?.lower_ema_value && (
                              <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                {errors?.lower_ema_value?.message}
                              </div>
                            )}
                          </div>
                          <div className="form-group col-md-2 pr-0">
                            <label>Upper EMA Value*</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Upper ema value"
                              {...register(`upper_ema_value`, {
                                required: "Upper ema value is required",
                                valueAsNumber: true, // Converts the input to a number
                                validate: (value) => Number.isInteger(value) || "Only integers are allowed"
                              })}
                              onKeyPress={(e) => {
                                if (e.key === '-' || e.key === '.' || e.key === 'e') {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {errors?.upper_ema_value && (
                              <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                {errors?.upper_ema_value?.message}
                              </div>
                            )}
                          </div>
                          <div className="form-group col-md-2 pr-0">
                            <label>Quantity*</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Quantity"
                              {...register(`quantity`, {
                                required: "Quantity is required",
                                valueAsNumber: true, // Converts the input to a number
                                validate: (value) => Number.isInteger(value) || "Only integers are allowed"
                              })}
                              onKeyPress={(e) => {
                                if (e.key === '-' || e.key === '.' || e.key === 'e') {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {errors?.quantity && (
                              <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                {errors?.quantity?.message}
                              </div>
                            )}
                          </div>
                          <div className="form-group col-md-2 pr-0">
                            <label>Interval*</label>
                            <select
                              className="form-control"
                              {...register("interval", { required: true })}
                              defaultValue=""
                            >
                              <option value="" disabled>Select...</option>
                              <option value="5s">5second</option>
                              <option value="10s">10second</option>
                              <option value="15s">15second</option>
                              <option value="30s">30second</option>
                              <option value="1m">1minute</option>
                              <option value="3m">3minute</option>
                              <option value="5m">5minute</option>
                              <option value="15m">15minute</option>
                            </select>
                            {errors.interval && (
                              <span className="validationAlert">Interval is required</span>
                            )}
                          </div>
                          <div className="form-group col-md-2 pr-0">
                            <label>Overall Profit</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Overall Profit"
                              {...register(`overall_profit`, {
                                required: false,
                              })}
                              onKeyPress={(e) => {
                                if (e.key === '-' || e.key === '.' || e.key === 'e') {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {errors?.overall_profit && (
                              <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                {errors?.overall_profit?.message}
                              </div>
                            )}
                          </div>
                          <div className="form-group col-md-2 pr-0">
                            <label>Overall Loss</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Overall Loss"
                              {...register(`overall_loss`, {
                                required: false,
                              })}
                              onKeyPress={(e) => {
                                if (e.key === '-' || e.key === '.' || e.key === 'e') {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {errors?.overall_loss && (
                              <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                {errors?.overall_loss?.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row col-md-12 mb-4 pr-0">
                          {segment === "option" ?
                            <div className="form-group col-md-2 pr-0">
                              <label>Side*</label>
                              <select
                                className="form-control"
                                {...register("side", { required: true })}
                                defaultValue=""
                                onChange={handleSideChange}
                              >
                                <option value="" disabled>Select...</option>
                                <option value="call">Call</option>
                                <option value="put">Put</option>
                                <option value="both">Both</option>
                              </select>
                              {errors.side && (
                                <span className="validationAlert">Side is required</span>
                              )}
                            </div>
                            : null}
                          {segment === "option" && (selectedSide === 'call' || selectedSide === 'both') ? (
                            <div className="form-group col-md-2 pr-0">
                              <label>Call Variation*</label>
                              <select
                                className="form-control"
                                {...register("call_variation", { required: selectedSide === 'call' || selectedSide === 'both' })}
                                defaultValue=""
                              >
                                <option value="" disabled>Select...</option>
                                <option value="1000_ITM">1000 ITM</option>
                                <option value="900_ITM">900 ITM</option>
                                <option value="800_ITM">800 ITM</option>
                                <option value="700_ITM">700 ITM</option>
                                <option value="600_ITM">600 ITM</option>
                                <option value="500_ITM">500 ITM</option>
                                <option value="400_ITM">400 ITM</option>
                                <option value="300_ITM">300 ITM</option>
                                <option value="200_ITM">200 ITM</option>
                                <option value="100_ITM">100 ITM</option>
                                <option value="ATM">ATM</option>
                                <option value="100_OTM">100 OTM</option>
                                <option value="200_OTM">200 OTM</option>
                                <option value="300_OTM">300 OTM</option>
                                <option value="400_OTM">400 OTM</option>
                                <option value="500_OTM">500 OTM</option>
                                <option value="600_OTM">600 OTM</option>
                                <option value="700_OTM">700 OTM</option>
                                <option value="800_OTM">800 OTM</option>
                                <option value="900_OTM">900 OTM</option>
                                <option value="1000_OTM">1000 OTM</option>
                              </select>
                              {errors.callVariation && (
                                <span className="validationAlert">Call Variation is required</span>
                              )}
                            </div>
                          ) : null}

                          {segment === "option" && (selectedSide === 'put' || selectedSide === 'both') ? (
                            <div className="form-group col-md-2 pr-0">
                              <label>Put Variation*</label>
                              <select
                                className="form-control"
                                {...register("put_variation", { required: selectedSide === 'put' || selectedSide === 'both' })}
                                defaultValue=""
                              >
                                <option value="" disabled>Select...</option>
                                <option value="1000_ITM">1000 ITM</option>
                                <option value="900_ITM">900 ITM</option>
                                <option value="800_ITM">800 ITM</option>
                                <option value="700_ITM">700 ITM</option>
                                <option value="600_ITM">600 ITM</option>
                                <option value="500_ITM">500 ITM</option>
                                <option value="400_ITM">400 ITM</option>
                                <option value="300_ITM">300 ITM</option>
                                <option value="200_ITM">200 ITM</option>
                                <option value="100_ITM">100 ITM</option>
                                <option value="ATM">ATM</option>
                                <option value="100_OTM">100 OTM</option>
                                <option value="200_OTM">200 OTM</option>
                                <option value="300_OTM">300 OTM</option>
                                <option value="400_OTM">400 OTM</option>
                                <option value="500_OTM">500 OTM</option>
                                <option value="600_OTM">600 OTM</option>
                                <option value="700_OTM">700 OTM</option>
                                <option value="800_OTM">800 OTM</option>
                                <option value="900_OTM">900 OTM</option>
                                <option value="1000_OTM">1000 OTM</option>
                              </select>
                              {errors.putVariation && (
                                <span className="validationAlert">Put Variation is required</span>
                              )}
                            </div>
                          ) : null}

                          <div className="form-group col-md-2 pr-0">
                            <label>Traditional Brick Size*</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Traditional Brick Size"
                              {...register(`brick_size`, {
                                required: "Brick Size is required",
                              })}
                              onKeyPress={(e) => {

                                if (e.key === '-' || e.key === '.' || e.key === 'e') {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {errors?.brick_size && (
                              <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                {errors?.brick_size?.message}
                              </div>
                            )}
                          </div>
                          <div className="form-group col-md-2 pr-0">
                            <label>Last Brick High*</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Last Brick High"
                              {...register(`last_brick_high`, {
                                required: "Last brick high is require",
                              })}
                              onKeyPress={(e) => {

                                if (e.key === '-' || e.key === '.' || e.key === 'e') {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {errors?.last_brick_high && (
                              <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                {errors?.last_brick_high?.message}
                              </div>
                            )}
                          </div>
                          <div className="form-group col-md-2 pr-0">
                            <label>Last Brick Low*</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Last Brick Low"
                              {...register(`last_brick_low`, {
                                required: "Last brick low is require",
                              })}
                              onKeyPress={(e) => {

                                if (e.key === '-' || e.key === '.' || e.key === 'e') {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {errors?.last_brick_low && (
                              <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                {errors?.last_brick_low?.message}
                              </div>
                            )}
                          </div>
                        </div>


                        <div className="mt-2 text-center">
                          <button
                            type="submit"
                            className="btn btn-primary ml-3 px-4"
                          >
                            Save & Continue
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default RenkoEMAStrategyAdd;
