// BASIC IMPORTS
import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactReduxContext, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

// MUI IMPORTS
import { Box, Paper } from "@mui/material";

// FILE IMPORTS
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { SharedStateProvider } from "../../main/SharedStateContext";

// API IMPORTS
import { uploadExcelCreate, angelSymbolPost, uploadUpstoxSymbolFile, fyersSymbolPost } from "../../../actions/uploadExcel";


function UploadExcel() {
  const {
    handleSubmit: handleSubmitFyers,
    register: registerFyers,
    reset: resetFyers,
    formState: { errors: errorsFyers },
  } = useForm();
  const {
    handleSubmit: handleSubmitAngel,
    register: registerAngel,
    reset: resetAngel,
    formState: { errors: errorsAngel },
  } = useForm();

  const [selectedfileICICI, SetSelectedFileICICI] = useState({});
  const [selectedfileUPSTOX, SetSelectedFileUPSTOX] = useState({});
  const dispatch = useDispatch();
  const { store } = useContext(ReactReduxContext);

  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const InputChange = (e, account) => {
    if (e.target.files[0] !== undefined) {
      const selectedFile = {};
      selectedFile["filename"] = e.target.files[0]?.name;
      selectedFile["filetype"] = e.target.files[0]?.type;
      selectedFile["datetime"] =
        e.target.files[0]?.lastModifiedDate.toLocaleString("en-IN");
      selectedFile["filesize"] = filesizes(e.target.files[0]?.size);
      selectedFile["file"] = e.target.files[0];

      if (account === "ICICI") {
        SetSelectedFileICICI(selectedFile);
      } else if (account === "UPSTOX") {
        SetSelectedFileUPSTOX(selectedFile);
      }
    }
  };

  const handleUploadExcelFile = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    if (selectedfileICICI.file) {
      formData.append("file", selectedfileICICI.file);
      await uploadExcelCreate(dispatch, formData);
      if (store.getState()?.uploadExcel?.response) {
        SetSelectedFileICICI();
        toast.success("File Successfully Uploaded");
      } else if (store.getState()?.uploadExcel?.error) {
        toast.error(
          store.getState()?.uploadExcel?.errorMessage
            ? store.getState()?.uploadExcel?.errorMessage?.error
            : "Something went wrong please try again"
        );
      } else {
        toast.warning("Select Any One File");
      }
    }
  };

  const handleUploadJsonFile = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    if (selectedfileUPSTOX.file) {
      formData.append("file", selectedfileUPSTOX.file);
      await uploadUpstoxSymbolFile(dispatch, formData);
      if (store.getState()?.uploadExcel?.upstox_symbol) {
        SetSelectedFileUPSTOX({});
        toast.success("File Successfully Uploaded");
      } else if (store.getState()?.uploadExcel?.error) {
        toast.error(
          store.getState()?.uploadExcel?.errorMessage
            ? store.getState()?.uploadExcel?.errorMessage?.error
            : "Something went wrong please try again"
        );
      } else {
        toast.warning("Select Any One File");
      }
    }
  };

  const onSubmitAngel = async (data) => {
    await angelSymbolPost(dispatch, data);
    if (store.getState()?.uploadExcel?.angel_symbol) {
      toast.success("File Successfully Uploaded");
      resetAngel();
    } else if (store.getState()?.uploadExcel?.error) {
      toast.error(
        store.getState()?.uploadExcel?.errorMessage
          ? store.getState()?.uploadExcel?.errorMessage?.error
          : "Something went wrong please try again"
      );
    }
  };

  const onSubmitFyers = async (data) => {
    await fyersSymbolPost(dispatch, data);
    if (store.getState()?.uploadExcel?.fyers_symbol) {
      toast.success("File Successfully Uploaded");
      resetFyers();
    } else if (store.getState()?.uploadExcel?.error) {
      toast.error(
        store.getState()?.uploadExcel?.errorMessage
          ? store.getState()?.uploadExcel?.errorMessage?.error
          : "Something went wrong please try again"
      );
    }
  };

  return (
    <div className="Main">
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <section className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="page-header">
                <div className="page-block">
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <ul className="breadcrumb p-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/upload-excel" style={{ fontWeight: 600 }}>
                            Upload File
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-body">
                <div className="page-wrapper">
                  <Box sx={{ width: "100%" }}>
                    <Paper
                      sx={{ width: "100%", mb: 2, p: 4 }}
                      className="settingForm"
                    >
                      <div>
                        <div className="fileupload-view">
                          <div className="row justify-content-center m-0">
                            {/* ICICI File Upload Code */}
                            <div className="col-md-6">
                              <div
                                className="card mt-4"
                                style={{ borderRadius: "7px" }}
                              >
                                <div className="card-body customCard">
                                  <h4 style={{ color: "blue" }}>
                                    Upload ICICI Symbol Files :-
                                  </h4>
                                  <div className="kb-data-box">
                                    <div className="kb-modal-data-title">
                                      <div className="kb-data-title">
                                        <h6 style={{ color: "teal" }}>
                                          NSEScriptMaster :: BSEScriptMaster ::
                                          CDNSEScriptMaster :: FONSEScriptMaster :: FOBSEScriptMaster
                                        </h6>
                                        <h6 className="pt-2">
                                          Upload only above name file
                                          <a
                                            href="https://directlink.icicidirect.com/NewSecurityMaster/SecurityMaster.zip"
                                            className="pl-3"
                                          >
                                            Download ZIP
                                          </a>
                                        </h6>
                                      </div>
                                    </div>
                                    <form>
                                      <div className="kb-file-upload">
                                        <div className="file-upload-box">
                                          <input
                                            type="file"
                                            id="fileupload"
                                            className="file-upload-input"
                                            onChange={(e) =>
                                              InputChange(e, "ICICI")
                                            }
                                            multiple
                                          />
                                          <span>
                                            Drag and drop or
                                            <span className="file-link">
                                              Choose your files
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                      <div className="kb-attach-box mb-3">
                                        <div className="file-atc-box">
                                          {selectedfileICICI?.filename ? (
                                            <div className="file-detail">
                                              <h6>
                                                {selectedfileICICI?.filename}
                                              </h6>
                                              <p></p>
                                              <p>
                                                <span>
                                                  Size :{" "}
                                                  {selectedfileICICI?.filesize}
                                                </span>
                                                <span className="ml-2">
                                                  Modified Time :
                                                  {selectedfileICICI?.datetime}
                                                </span>
                                              </p>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                      <div className="kb-buttons-box text-center">
                                        <button
                                          type="submit"
                                          className="btn btn-primary form-submit"
                                          onClick={handleUploadExcelFile}
                                        >
                                          Upload
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Upstox File Upload Code */}
                            <div className="col-md-6">
                              <div
                                className="card mt-4"
                                style={{ borderRadius: "7px" }}
                              >
                                <div className="card-body customCard">
                                  <h4 style={{ color: "blue" }}>
                                    Upload UPSTOX Symbol File :-
                                  </h4>
                                  <div className="kb-data-box">
                                    <div className="kb-modal-data-title">
                                      <div className="kb-data-title">
                                        <h6 style={{ color: "teal" }}>
                                          Upload complete.json File
                                        </h6>
                                        <h6
                                          className="pt-2"
                                          style={{ paddingBottom: "18px" }}
                                        >
                                          Upload only above name file
                                          <a
                                            href="https://assets.upstox.com/market-quote/instruments/exchange/complete.json.gz"
                                            className="pl-3"
                                          >
                                            Download JSON
                                          </a>
                                        </h6>
                                      </div>
                                    </div>
                                    <form>
                                      <div className="kb-file-upload">
                                        <div className="file-upload-box">
                                          <input
                                            type="file"
                                            id="upstoxFileUpload"
                                            className="file-upload-input"
                                            onChange={(e) =>
                                              InputChange(e, "UPSTOX")
                                            }
                                            multiple
                                          />
                                          <span>
                                            Drag and drop or
                                            <span className="file-link">
                                              Choose your files
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                      <div className="kb-attach-box mb-3">
                                        <div className="file-atc-box">
                                          {selectedfileUPSTOX?.filename ? (
                                            <div className="file-detail">
                                              <h6>
                                                {selectedfileUPSTOX?.filename}
                                              </h6>
                                              <p></p>
                                              <p>
                                                <span>
                                                  Size :{" "}
                                                  {selectedfileUPSTOX?.filesize}
                                                </span>
                                                <span className="ml-2">
                                                  Modified Time :
                                                  {selectedfileUPSTOX?.datetime}
                                                </span>
                                              </p>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                      <div className="kb-buttons-box text-center">
                                        <button
                                          type="submit"
                                          className="btn btn-primary form-submit"
                                          onClick={handleUploadJsonFile}
                                        >
                                          Upload
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* FYERS File Upload Code */}
                            <div className="col-md-6">
                              <div
                                className="card mt-4"
                                style={{ borderRadius: "7px" }}
                              >
                                <div className="card-body customCard">
                                  <h4 style={{ color: "blue" }}>
                                    Add Symbols Link For Fyers :-
                                  </h4>
                                  <div className="kb-data-box">
                                    <div className="kb-modal-data-title">
                                      <span style={{ color: "goldenrod" }}>
                                        Fyers Symbol Link :-
                                      </span>
                                      <br></br>
                                      <a
                                        href="https://public.fyers.in/sym_details/NSE_CM.csv"
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {" "}
                                        https://public.fyers.in/sym_details/NSE_CM.csv
                                      </a><br></br>
                                      <a
                                        href="https://public.fyers.in/sym_details/NSE_FO.csv"
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {" "}
                                        https://public.fyers.in/sym_details/NSE_FO.csv
                                      </a><br></br>
                                      <a
                                        href="https://public.fyers.in/sym_details/BSE_CM.csv"
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {" "}
                                        https://public.fyers.in/sym_details/BSE_CM.csv
                                      </a><br></br>
                                      <a
                                        href="https://public.fyers.in/sym_details/BSE_FO.csv"
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {" "}
                                        https://public.fyers.in/sym_details/BSE_FO.csv
                                      </a>
                                      <a
                                        href="https://public.fyers.in/sym_details/MCX_COM.csv"
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {" "}
                                        https://public.fyers.in/sym_details/MCX_COM.csv
                                      </a>
                                    </div>
                                    <form
                                      id="FyersAddSymbol"
                                      onSubmit={handleSubmitFyers(
                                        onSubmitFyers
                                      )}
                                    >
                                      <div className="mt-4">
                                        <input
                                          type="name"
                                          name="symbolLinkFyers"
                                          className="form-control"
                                          placeholder="Link"
                                          {...registerFyers("symbolLinkFyers", {
                                            required: "Link is required",
                                          })}
                                        />
                                        {errorsFyers?.symbolLinkFyers && (
                                          <div
                                            className="validationAlert"
                                            style={{ margin: "10px 0px" }}
                                          >
                                            {
                                              errorsFyers?.symbolLinkFyers
                                                ?.message
                                            }
                                          </div>
                                        )}
                                      </div>
                                      <div className="text-center mt-4">
                                        <button
                                          type="submit"
                                          className="btn btn-primary form-submit"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Angel File Upload Code */}
                            <div className="col-md-6">
                              <div
                                className="card mt-4"
                                style={{ borderRadius: "7px" }}
                              >
                                <div className="card-body customCard">
                                  <h4 style={{ color: "blue" }}>
                                    Add Symbols Link For Angel :-
                                  </h4>
                                  <span style={{ color: "goldenrod" }}>
                                    Angel Symbol Link :-
                                  </span>
                                  <a
                                    href="https://margincalculator.angelbroking.com/OpenAPI_File/files/OpenAPIScripMaster.json"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {" "}
                                    https://margincalculator.angelbroking.com/OpenAPI_File/files/OpenAPIScripMaster.json
                                  </a>
                                  <div className="kb-data-box">
                                    <form
                                      id="AngelAddSymbol"
                                      onSubmit={handleSubmitAngel(
                                        onSubmitAngel
                                      )}
                                    >
                                      <div className="mt-4">
                                        <input
                                          type="name"
                                          name="symbolLinkAngel"
                                          className="form-control"
                                          placeholder="Link"
                                          {...registerAngel("symbolLinkAngel", {
                                            required: "Link is required",
                                          })}
                                        />
                                        {errorsAngel?.symbolLinkAngel && (
                                          <div
                                            className="validationAlert"
                                            style={{ margin: "10px 0px" }}
                                          >
                                            {
                                              errorsAngel?.symbolLinkAngel
                                                ?.message
                                            }
                                          </div>
                                        )}
                                      </div>
                                      <div className="text-center mt-4">
                                        <button
                                          type="submit"
                                          className="btn btn-primary form-submit"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Paper>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default UploadExcel;
