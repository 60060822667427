import axiosClient from "../axios-client";


export const saveOptionStrategyThreeData = async (dispatch, data) => {
    try {
        const res = await axiosClient.post("/option_strategy_three", data);
        return res?.data;
    } catch (error) {
        throw error;
    } finally {
    }
};

export const getOptionStrategyThreeData = async (dispatch) => {
    try {
        const res = await axiosClient.get("/get_option_strategy_three");
        return res?.data;
    } catch (error) {
        throw error;
    } finally {
    }
};

export const updateOptionStrategyThreeStatus = async (
    dispatch,
    strategy_id,
    status
) => {
    try {
        const res = await axiosClient.put(
            `/update_option_strategy_three_script_status/${strategy_id}/${status}`
        );
        return res;
    } catch (error) {
        throw error;
    } finally {
    }
};

export const startOptionStrategyThree = async (dispatch, strategy_id) => {
    try {
        const res = await axiosClient.get(
            `/start_option_strategy_three/${strategy_id}`
        );
        return res;
    } catch (error) {
        throw error;
    } finally {
    }
};

export const getOptionStrategyThreeById = async (dispatch, strategy_id) => {
    try {
        const res = await axiosClient.get(
            `/get_option_strategy_three/${strategy_id}`
        );
        return res?.data?.data;
    } catch (error) {
        throw error;
    } finally {
    }
};

export const updateOptionStrategyThree = async (
    dispatch,
    strategy_id,
    strategy_data
) => {
    try {
        const res = await axiosClient.put(
            `/update_option_strategy_three/${strategy_id}`,
            strategy_data
        );
        return res?.data?.data;
    } catch (error) {
        throw error;
    } finally {
    }
};

export const deleteOptionStrategyThree = async (dispatch, strategy_id) => {
    try {
        const res = await axiosClient.delete(
            `/delete_option_strategy_three/${strategy_id}`
        );
        return res?.data?.message;
    } catch (error) {
        throw error;
    } finally {
    }
};

export const updateOptionStrategyThreeExpiryDate = async (strategy_id, expiry_date) => {
    try {
        const res = await axiosClient.put(`/update_option_strategy_three_expiry/${strategy_id}`, expiry_date);
        return res?.data?.message;
    } catch (error) {
        throw error;
    } finally {
    }
};