import axiosClient from "../axios-client";


export const saveRenkoEmaStrategy = async (dispatch, data) => {
    try {
        const res = await axiosClient.post("/renko_ema_strategy", data);
        return res?.data;
    } catch (error) {
        throw error;
    } finally {
    }
};

export const getRenkoEmaStrategies = async (dispatch) => {
    try {
        const res = await axiosClient.get("/get_renko_ema_strategies");
        return res?.data;
    } catch (error) {
        throw error;
    } finally {
    }
};

export const getRenkoEmaStrategyOrders = async (strategy_id) => {
    try {
        const res = await axiosClient.get(`/get_renko_ema_strategy_orders/${strategy_id}`);
        return res?.data;
    } catch (error) {
        throw error;
    } finally {
    }
};

export const getRenkoEmaStrategyByID = async (dispatch, strategy_id) => {
    try {
        const res = await axiosClient.get(`/get_renko_ema_strategy/${strategy_id}`);
        return res?.data;
    } catch (error) {
        throw error;
    } finally {
    }
};

export const updateRenkoEmaStrategy = async (dispatch, strategy_id, data) => {
    try {
        const res = await axiosClient.put(`/renko_ema_strategy/${strategy_id}`, data);
        return res?.data;
    } catch (error) {
        throw error;
    } finally {
    }
};

export const deleteRenkoEmaStrategy = async (dispatch, strategy_id) => {
    try {
        const res = await axiosClient.delete(`/renko_ema_strategy/${strategy_id}`);
        return res?.data;
    } catch (error) {
        throw error;
    } finally {
    }
};


export const startRenkoEmaStrategy = async (dispatch, strategy_id) => {
    try {
        const res = await axiosClient.get(`/start_renko_ema_Strategy/${strategy_id}`);
        return res?.data;
    } catch (error) {
        throw error;
    } finally {
    }
};


export const updateRenkoEmaStrategyExpiryDate = async (strategy_id, expiry_date) => {
    try {
        const res = await axiosClient.put(`/update_renko_ema_strategy_expiry/${strategy_id}`, expiry_date);
        return res?.data?.message;
    } catch (error) {
        throw error;
    } finally {
    }
};


export const updateRenkoEmaStrategyStatus = async (strategy_id, status) => {
    try {
        const res = await axiosClient.put(`/update_renko_ema_strategy_script_status/${strategy_id}/${status}`);
        return res;
    } catch (error) {
        throw error;
    } finally {
    }
};


export const getDeletedRenkoStrategies = async (dispatch) => {
    try {
        const res = await axiosClient.get("/deleted_strategies");
        return res?.data;
    } catch (error) {
        throw error;
    } finally {
    }
};


export const hardDeleteStrategy = async (data) => {
    try {
        const res = await axiosClient.post("/hard_delete_strategy", data);
        return res?.data;
    } catch (error) {
        throw error;
    } finally {
    }
};