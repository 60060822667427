import axiosClient from "../axios-client";


export const saveOptionStrategyTwoData = async (dispatch, data) => {
    try {
        const res = await axiosClient.post("/option_strategy_two", data);
        return res?.data;
    } catch (error) {
        throw error;
    } finally {
    }
};

export const getOptionStrategyTwoData = async (dispatch) => {
    try {
        const res = await axiosClient.get("/get_option_strategy_two");
        return res?.data;
    } catch (error) {
        throw error;
    } finally {
    }
};


export const getAtmStrategyPnl = async (dispatch) => {
    try {
        const res = await axiosClient.get("/get_atm_strategy_position_pnl");
        return res?.data;
    } catch (error) {
        throw error;
    } finally {
    }
};

export const updateOptionStrategyTwoStatus = async (
    dispatch,
    strategy_id,
    status
) => {
    try {
        const res = await axiosClient.put(
            `/update_option_strategy_two_script_status/${strategy_id}/${status}`
        );
        return res;
    } catch (error) {
        throw error;
    } finally {
    }
};

export const startOptionStrategyTwo = async (dispatch, strategy_id) => {
    try {
        const res = await axiosClient.get(
            `/start_option_strategy_two/${strategy_id}`
        );
        return res;
    } catch (error) {
        throw error;
    } finally {
    }
};

export const getOptionStrategyTwoById = async (dispatch, strategy_id) => {
    try {
        const res = await axiosClient.get(
            `/get_option_strategy_two/${strategy_id}`
        );
        return res?.data?.data;
    } catch (error) {
        throw error;
    } finally {
    }
};

export const updateOptionStrategyTwo = async (
    dispatch,
    strategy_id,
    strategy_data
) => {
    try {
        const res = await axiosClient.put(
            `/update_option_strategy_two/${strategy_id}`,
            strategy_data
        );
        return res?.data?.data;
    } catch (error) {
        throw error;
    } finally {
    }
};

export const deleteOptionStrategyTwo = async (dispatch, strategy_id) => {
    try {
        const res = await axiosClient.delete(
            `/delete_option_strategy_two/${strategy_id}`
        );
        return res?.data?.message;
    } catch (error) {
        throw error;
    } finally {
    }
};

export const updateOptionStrategyTwoExpiryDate = async (strategy_id, expiry_date) => {
    try {
        const res = await axiosClient.put(`/update_option_strategy_two_expiry/${strategy_id}`, expiry_date);
        return res?.data?.message;
    } catch (error) {
        throw error;
    } finally {
    }
};
