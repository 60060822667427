import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Typography, TableBody, Paper, Table, TableHead, TableRow, TableCell, TableContainer, FormControl, Dialog, DialogContent, DialogTitle, Pagination } from '@mui/material';
import { SharedStateProvider } from "../SharedStateContext";
import { deleteEmaStrategy, getEmaStrategies, getEmaStrategyOrders, startEmaStrategy, updateEmaStrategyExpiryDate, updateEmaStrategyStatus } from "../../../actions/emaStrategyAction";
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { getSymbolExpirys } from "../../../actions/optionStrategyOneAction";


function EMAStrategy() {
  const { control, handleSubmit, register, reset, watch, formState: { errors } } = useForm();

  const [strategies, setStrategies] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [activeId, setActiveId] = useState();
  const [expiry, setExpiry] = useState();
  const [orders, setOrders] = useState({});
  const [pageNumbers, setPageNumbers] = useState({});
  const rowsPerPage = 5;

  useEffect(() => {
    // Initialize page numbers based on strategies count
    const initialPageNumbers = {};
    strategies.forEach((_, index) => {
      initialPageNumbers[index] = 1; // Start each strategy at page 1
    });
    setPageNumbers(initialPageNumbers);
  }, [strategies]);

  const handlePageChange = (index, newPage) => {
    setPageNumbers((prev) => ({
      ...prev,
      [index]: newPage, // Update the page number for the selected strategy
    }));
  };



  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const hasCheckedOnce = useRef(false);
  // const intervalId = useRef(null);

  const getEmaStrategyData = async () => {
    await getEmaStrategies(dispatch).then((res) => {
      const { strategies } = res?.data || {};
      setStrategies(strategies || []);
    }).catch(() => { });
  };

  const handleCloseModal = () => {
    setOpenModal(false); // Close the modal
  }

  const handleConfirm = () => {
    const selectedExpiry = watch('expiry_date');
    if (selectedExpiry) {
      updateEmaStrategyExpiryDate(activeId, { "expiry_date": selectedExpiry }).then((res) => {
        toast.success("Expiry updated successfully")
        getEmaStrategyData();
      }).catch((error) => {
        toast.error("Expiry not updated, due to some error")
      })
    } else {
      toast.error('Expiry date is required.');
    }
    handleCloseModal();
  }

  const handleExpiry = async (data) => {
    const expiryData = { "symbol": data?.symbol, "companyName": data?.companyName, "account_id": data?.account_id, "exchange_segment": data?.exchange, "segment": data?.segment }
    if (!expiry && expiryData) {
      await getSymbolExpirys(dispatch, expiryData).then((res) => {
        setExpiry(res || [])
      }).catch((error) => { });
    }
  };

  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split('-').map(Number);
    return new Date(year, month - 1, day); // Month is zero-based in JavaScript
  };

  const handleStartStrategy = (data, strategyType, statusType) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Reset the time part to 00:00:00
    const expiry_date = parseDate(data?.expiry_date);

    if (currentDate > expiry_date) {
      setOpenModal(true); // Open the modal
      reset();
      handleExpiry(data);
      setActiveId(data?.id);
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: `Are you sure you want to start this ${strategyType} strategy?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, start it!"
      }).then(async (result) => {
        if (result.isConfirmed) {
          updateEmaStrategyStatus(data?.id, `${strategyType}-${statusType}`)
            .then(async (res) => {
              getEmaStrategyData();
              await startEmaStrategy(dispatch, data?.id)
                .then(() => {
                  toast.success(`Strategy successfully running as ${strategyType}`);
                })
                .catch((error) => {
                  toast.error("Error starting the strategy");
                });
            })
            .catch((error) => {
              if (error?.response?.data?.error) {
                toast.error(error?.response?.data?.error);
              }
            });
        }
      });
    }
  };

  // Usage for live strategy
  const handleStartLiveStrategy = (data) => {
    handleStartStrategy(data, "live", "running");
  };

  // Usage for test strategy
  const handleStartTestStrategy = (data) => {
    handleStartStrategy(data, "test", "running");
  };

  const handleDisableStrategy = (id) => {
    updateEmaStrategyStatus(id, "disable").then(async (res) => {
      toast.success(res?.data?.message);
      await getEmaStrategyOrders(id).then((res) => {
        setOrders(res || {})
        updateOrders(id, res)
      }).catch((error) => { });
    }).catch((error) => { });
  }


  const handleStopLiveStrategy = (id) => {
    updateEmaStrategyStatus(id, "stop").then(async (res) => {
      toast.success(res?.data?.message);
      await getEmaStrategyOrders(id).then((res) => {
        setOrders(res || {})
        updateOrders(id, res)
      }).catch((error) => { });
    }).catch((error) => { });
  }


  const handleGetStrategyOrders = (id) => {
    getEmaStrategyOrders(id).then((res) => {
      setOrders(res || {})
      updateOrders(id, res)
    }).catch((error) => { });
  }


  const updateOrders = (id, res) => {
    setStrategies(prevData =>
      prevData?.map(data =>
        data?.id === id
          ? { ...data, orders: Object.values(res)[0]?.orders, overall_pnl: Object.values(res)[0]?.overall_pnl, position_pnl: Object.values(res)[0]?.position_pnl, status: Object.values(res)[0]?.status }
          : data
      )
    );
  };


  const handleEditStrategy = (id) => {
    navigate(`/ema-strategy/edit/${id}`)
  }


  const handleDeleteStrategy = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Are you sure you want to delete this strategy?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        deleteEmaStrategy(dispatch, id).then((res) => {
          toast.success(res?.message);
          getEmaStrategyData();
        }).catch((error) => {
          toast.error("Strategy not deleted, due to some error");
        });
      }
    });
  }

  useEffect(() => {
    getEmaStrategyData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="Main">
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <section className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="page-header">
                <div className="page-block">
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <ul className="breadcrumb p-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/ema-strategy" style={{ fontWeight: 600 }}>
                            EMA Strategy
                          </Link>
                        </li>
                      </ul>
                      <div className="page-header-title d-flex justify-content-between pb-3">
                        <div className="ListWithBadge">
                          <h4 className="m-b-20 mr-2">Strategies ({strategies?.length}) & Trades</h4>
                        </div>
                        <div className="orderCreateButton">
                          <button
                            type="button"
                            className="btn btn-square btn-warning"
                            onClick={() => navigate("/ema-backtest")}
                          >
                            Backtest
                          </button>
                          <button
                            type="button"
                            className="btn btn-square btn-secondary"
                            onClick={() => navigate("/ema-strategy/create")}
                          >
                            <i className="feather icon-plus"></i> Add Strategy
                          </button>
                          <button
                            type="button"
                            className="btn btn-square btn-primary"
                            onClick={() => navigate("/option-strategy-two-symbols")}
                          >
                            <i className="feather icon-plus"></i> Add Symbols
                          </button>
                          <button className="btn btn-danger" style={{ padding: "14px 20px" }} onClick={() => navigate("/ema-delete-strategy")}><i className="feather icon-trash mr-0"></i></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-body">
                <div className="page-wrapper">
                  {/* {strategies?.sort((a, b) => ((a.status === "test-running" || a.status === "live-running") ? -1 : 1)).map((data, index) => ( */}
                  {strategies?.map((data, index) => (
                    <div key={index} className="card" style={{ marginBottom: '20px' }}>
                      {/* Card Header */}
                      <div className={(data?.status === "test-running" || data?.status === "live-running") ? "card-header d-flex justify-content-between active" : "card-header d-flex justify-content-between"}>
                        <div>
                          <h5 className="card-title mb-3">Account: {data?.account_name}</h5>
                          <h6 className="card-subtitle mb-2" style={{ color: data?.status === "error" ? 'red' : 'black' }}>
                            Status: {data?.status} || Type: {data?.segment}
                          </h6>
                        </div>
                        <div>
                          {/* Action Buttons */}
                          <button className="btn btn-outline-danger mb-0" onClick={() => handleDisableStrategy(data?.id)} >Disable</button>
                          
                          <span className="ml-3 mr-4">||</span>

                          <button className="btn btn-outline-primary mb-0" onClick={() => handleStartTestStrategy(data)} disabled={(data?.status === "test-running" || data?.status === "live-running") || data?.status === "stop"}>Test Start</button>
                          <button className="btn btn-outline-danger mb-0" onClick={() => handleStopLiveStrategy(data?.id)} disabled={data?.status === "stop" || data?.status === "complete" || data?.status === "live-running"}>Test Stop</button>

                          <span className="ml-3 mr-4">||</span>

                          <button className="btn btn-outline-primary mb-0" onClick={() => handleStartLiveStrategy(data)} disabled={(data?.status === "test-running" || data?.status === "live-running") || data?.status === "stop"}>Live Start</button>
                          <button className="btn btn-outline-danger mb-0" onClick={() => handleStopLiveStrategy(data?.id)} disabled={data?.status === "stop" || data?.status === "complete" || data?.status === "test-running"}>Live Stop</button>

                          <span className="ml-3 mr-4">||</span>

                          <button className="btn btn-secondary mb-0" style={{ padding: "14px 20px" }} onClick={() => handleGetStrategyOrders(data?.id)}><i className="feather icon-refresh-ccw mr-0"></i></button>
                          <button className="btn btn-primary mb-0" style={{ padding: "14px 20px" }} onClick={() => handleEditStrategy(data?.id)} disabled={(data?.status === "test-running" || data?.status === "live-running")}><i className="feather icon-edit mr-0"></i></button>
                          <button className="btn btn-danger mb-0" style={{ padding: "14px 20px" }} onClick={() => handleDeleteStrategy(data?.id)} disabled={(data?.status === "test-running" || data?.status === "live-running")}><i className="feather icon-trash mr-0"></i></button>
                        </div>
                      </div>

                      {/* Card Body */}
                      <div className="card-body col-md-12 d-flex">
                        <div className="col-md-4">
                          <p className="card-text"><strong>Symbol:</strong> {data?.symbol}</p>
                          <p className="card-text"><strong>Expiry Date:</strong> {data?.expiry_date}</p>
                          <p className="card-text"><strong>Running Days:</strong> {data?.runningDays?.length > 0 ? data?.runningDays.join(', ') : "N/A"}</p>
                        </div>
                        <div className="col-md-2">
                          <p className="card-text"><strong>Lower EMA:</strong> {data?.lower_ema_value}</p>
                          <p className="card-text"><strong>Upper EMA:</strong> {data?.upper_ema_value}</p>
                          <p className="card-text"><strong>Quantity:</strong> {data?.quantity}</p>
                        </div>
                        <div className="col-md-3">
                          <p className="card-text"><strong>Interval:</strong> {data?.interval}</p>
                          <p className="card-text"><strong>Overall Profit:</strong> {data?.overall_profit ? data?.overall_profit : "N/A"}{data?.overallProfitVal === false ? "%" : ""}</p>
                          <p className="card-text"><strong>Overall Loss:</strong> {data?.overall_loss ? data?.overall_loss : "N/A"}{data?.overallLossVal === false ? "%" : ""}</p>
                        </div>
                        <div className="col-md-3">
                          {data?.side ? <p className="card-text"><strong>Side:</strong> {data?.side}</p> : null}
                          {data?.call_variation ? <p className="card-text"><strong>Call Variation:</strong> {data?.call_variation}</p> : null}
                          {data?.put_variation ? <p className="card-text"><strong>Put Variation:</strong> {data?.put_variation}</p> : null}
                        </div>
                      </div>

                      {/* Overall P&L */}
                      <div className="card mx-3" key={index}>
                        <div className="card-body py-3" style={{ backgroundColor: 'lavender', borderRadius: "5px" }}>
                          <label style={{ fontWeight: 600 }}>Symbol:</label> <span>{data?.symbol}</span>
                          <span className="mx-3">||</span>
                          <label style={{ fontWeight: 600 }}>Overall P&L:</label> <span>{data?.overall_pnl ? data?.overall_pnl : "N/A"}</span>
                        </div>
                      </div>

                      {/* Trade Triggers */}
                      {(Object.keys(orders).length > 0 && Object.keys(orders)[0] === data?.id) ? (
                        <div className="card-body px-4 pt-0">
                          <Typography variant="h6" gutterBottom>
                            Trade Triggers
                          </Typography>
                          <TableContainer component={Paper}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Time</TableCell>
                                  <TableCell>Symbol</TableCell>
                                  <TableCell>Trade</TableCell>
                                  <TableCell>P&L</TableCell>
                                  <TableCell>Position P&L</TableCell>
                                  <TableCell>Type</TableCell>
                                  <TableCell>Quantity</TableCell>
                                  <TableCell>Error</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {Object.values(orders)[0]?.orders?.slice(
                                  (pageNumbers[index] - 1) * rowsPerPage,
                                  pageNumbers[index] * rowsPerPage
                                )?.map((trigger, triggerIndex) => {
                                  // Check if it's the first row of the current card
                                  const isFirstRowOfCurrentCard = triggerIndex === 0;

                                  // Check if it's the first page
                                  const isFirstPage = pageNumbers[index] === 1;

                                  return (
                                    <TableRow key={triggerIndex}>
                                      <TableCell>{new Date(trigger?.datetime).toLocaleString()}</TableCell>
                                      <TableCell>{trigger?.symbol}</TableCell>
                                      <TableCell>{trigger?.trade} ({trigger.trade_price})</TableCell>
                                      <TableCell style={{ color: trigger?.profit < 0 ? 'red' : 'green' }}>
                                        {trigger?.profit}
                                      </TableCell>
                                      <TableCell style={{ color: isFirstRowOfCurrentCard && isFirstPage && (trigger?.profit === undefined) ? (data?.position_pnl < 0 ? 'red' : 'green') : 'black' }}>
                                        {isFirstRowOfCurrentCard && isFirstPage ?
                                          (trigger?.profit === undefined ? (data?.position_pnl !== undefined ? data?.position_pnl : "N/A") : "-")
                                          :
                                          "-"
                                        }
                                      </TableCell>
                                      <TableCell>{trigger?.type}</TableCell>
                                      <TableCell>{trigger?.quantity}</TableCell>
                                      <TableCell>{trigger?.message ? trigger?.message : "-"}</TableCell>
                                    </TableRow>
                                  );
                                })}

                                <TableRow>
                                  <TableCell colSpan={3} align="right"><strong>Total Profit:</strong></TableCell>
                                  <TableCell>
                                    <strong>
                                      {data?.orders?.length > 0
                                        ? data?.orders?.reduce((acc, trigger) => acc + (trigger?.profit || 0), 0).toFixed(2)
                                        : "N/A"
                                      }
                                    </strong>
                                  </TableCell>
                                  <TableCell colSpan={3} />
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>

                          {/* Pagination Component */}
                          <Pagination
                            count={Math.ceil(Object.values(orders)[0]?.orders?.length / rowsPerPage) || 1} // Calculate total pages, default to 1 if orders is empty
                            page={pageNumbers[index] || 1} // Get the current page for this strategy or default to 1
                            onChange={(event, value) => handlePageChange(index, value)} // Update page number on change
                            variant="outlined"
                            shape="rounded"
                            style={{ marginTop: '10px', float: 'right' }}
                          />
                        </div>
                      ) : null}

                    </div>
                  ))}
                </div>
              </div>


              <Dialog open={openModal} onClose={handleCloseModal}>
                <DialogTitle>Strategy Expiry expired, Select latest expiry</DialogTitle>
                <DialogContent>
                  <form onSubmit={handleSubmit(handleConfirm)}>
                    <FormControl fullWidth error={!!errors.expiry_date}>
                      <Controller
                        name="expiry_date"
                        control={control}
                        defaultValue=""
                        {...register("expiry_date", { required: true })}
                        render={({ field }) => (
                          <select
                            className="form-control"
                            {...field}
                            style={{ outline: 'none', boxShadow: 'none' }}
                          >
                            <option value="" disabled>Select...</option>
                            {expiry?.map((data, index) => (
                              <option key={index} value={data}>{data}</option>
                            ))}
                          </select>
                        )}
                      />
                      {errors.expiry_date && (
                        <span className="validationAlert">Expiry date is required</span>
                      )}
                    </FormControl>
                  </form>
                </DialogContent>
                <div className="text-center pt-0 pb-3">
                  <button onClick={handleSubmit(handleConfirm)} className="btn btn-primary">Update Expiry</button>
                  <button onClick={handleCloseModal} className="btn btn-danger">Cancle</button>
                </div>
              </Dialog>
            </div>
          </div>
        </div>
      </section >
    </div >
  );
}

export default EMAStrategy;