// BASIC IMPORTS
import React, { useContext, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { ReactReduxContext, useDispatch } from "react-redux";
import { toast } from "react-toastify";


// MUI IMPORTS
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { Button, TextField, Switch } from "@mui/material";


// FILE IMPORTS
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { SharedStateProvider } from "../SharedStateContext";


// API IMPORTS
import { tradingCreate } from "../../../actions/tradingAction";
import { exchangeProductSettingGet } from "../../../actions/exchangeAction";


function TradingCreateForm() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    watch,
  } = useForm({
    defaultValues: {
      buyStatus: "Pending",
      sellStatus: "Pending",
      status: "Enabled",
    },
  });

  
  const [filterData, setFilterData] = useState([]);
  const [lotSize, setLotSize] = useState(0);
  const [selectedSymbol, setSetectedSymbol] = useState(null);
  const [dropList, setDropList] = useState([]);
  const [accountSymbols, setAccountSymbols] = useState([]);
  const [account, setAccount] = useState([]);
  const [product, setProduct] = useState([]);
  

  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch();
  const watchSymbol = watch("companyName", "");
  const watchExchange = watch("exchange", "");


  const onSubmit = async (data) => {
    const expiry_date = selectedSymbol?.expiryDate
      ? selectedSymbol?.expiryDate
      : "";

    data["symbol"] = selectedSymbol?.shortName;
    data["tickSize"] = selectedSymbol?.tickSize;
    data["expiryDate"] = expiry_date;
    data["switchType"] = data?.switchType ? "Sell" : "Buy";

    if (selectedSymbol?.symbolToken !== null) {
      data["symbolToken"] = selectedSymbol?.symbolToken;
    }

    dropList?.setting?.forEach((dict) => {
      Object.entries(dict).forEach(([key, value]) => {
        Object.entries(value).forEach(([key_sub, value_sub]) => {
          if (key_sub === data["accountName"]) {
            data["accountId"] = key;
          }
        });
      });
    });

    const foundItem = product?.find(
      (item) => Object.values(item)[0] === data["product"]
    );
    const key = foundItem ? Object.keys(foundItem)[0] : null;
    data["product"] = key;
    if (key !== null) {
      await tradingCreate(dispatch, data);
      if (store.getState()?.trading?.trading) {
        toast.success("Data added successfully!");
        navigate("/trading");
      } else if (store.getState()?.trading?.error) {
        toast.error(
          store.getState()?.trading?.errorMessage
            ? store.getState()?.trading?.errorMessage?.message
            : "Error adding data. Please try again."
        );
      }
    }
  };


  const filterOptions = createFilterOptions({
    ignoreCase: true,
    limit: 100,
    matchFrom: "any",
    stringify: (option) => option?.companyName + option?.shortName,
  });


  const getSettingData = async () => {
    await exchangeProductSettingGet(dispatch);
    if (store.getState()?.exchange?.exchangeProductSettingList) {
      const res = store.getState()?.exchange?.exchangeProductSettingList;
      if (res) {
        setDropList(res?.data);
        setFilterData(res?.data?.symbols);
      }
    }
  };


  const filterSymbols = (account) => {
    const filteredData = dropList?.symbols?.filter((item) => {
      return item?.accountName === account;
    });
    setAccountSymbols(filteredData);
    setValue("companyName", "");
    setValue("symbol", "");
    setValue("product", "");
    setLotSize(0);
    setSetectedSymbol();
  };


  useEffect(() => {
    console.log("accountSymbols :: ", accountSymbols);
    if (watchExchange?.toUpperCase() === "NSE") {
      setFilterData(
        accountSymbols?.filter((item) => item?.marketType === "NSE")
      );
    } else if (watchExchange?.toUpperCase() === "BSE") {
      setFilterData(
        accountSymbols?.filter((item) => item?.marketType === "BSE")
      );
    } else if (watchExchange?.toUpperCase() === "NFO") {
      setFilterData(
        accountSymbols?.filter((item) => item?.marketType === "NFO")
      );
    } else if (watchExchange?.toUpperCase() === "MCX") {
      setFilterData(
        accountSymbols?.filter((item) => item?.marketType === "MCX")
      );
    } else {
      setFilterData(accountSymbols);
    }

    // eslint-disable-next-line
  }, [watchExchange, accountSymbols]);

  
  const getValueByKey = (keyToFind, symbols) => {
    if (!Array.isArray(symbols)) return null; // Make sure symbols is an array

    for (const symbol of symbols) {
      const symbolId = Object.keys(symbol)[0]; // Assuming there's always one key
      const symbolData = symbol[symbolId];
      if (symbolData.hasOwnProperty(keyToFind)) {
        return symbolData[keyToFind];
      }
    }

    return null; // Return null if key is not found
  };


  useEffect(() => {
    if (dropList && dropList?.setting) {
      const value = getValueByKey(account, dropList?.setting);
      if (value) {
        const filteredDatas = dropList?.product?.filter((data) => {
          return data?.includes(value);
        });
        const result = filteredDatas.map((str) => {
          const parts = str.split(" : ");
          return { [parts[1]]: parts[2] };
        });
        setProduct(result);
      }
    }
  }, [account, dropList]);


  useEffect(() => {
    if (accountSymbols) {
      setValue("companyName", "");
      setValue("symbol", "");
      setValue("product", "");
      setLotSize(0);
      setSetectedSymbol();
    }
    // eslint-disable-next-line
  }, [watchExchange]);


  useEffect(() => {
    getSettingData();
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <div className="Main">
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content"></div>
            <div className="main-body">
              <div className="page-wrapper">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <ul className="breadcrumb p-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/trading">Trading</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="" style={{ fontWeight: 600 }}>
                          Create New
                        </Link>
                      </li>
                    </ul>
                    <div className="page-header-title d-flex justify-content-between pb-3">
                      <div className="ListWithBadge">
                        <Button
                          className="adminbackBtn"
                          onClick={() => navigate("/trading")}
                        >
                          <i className="feather icon-chevron-right"></i>
                        </Button>
                        <h3 className="m-b-20 mr-2">Create New</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="card">
                        <div className="card-body mobileCardBody">
                          <div className="row col-md-12">
                            <div className="form-group col-md-12">
                              <label>Account*</label>
                              <Controller
                                name="accountName"
                                control={control}
                                rules={{
                                  required: "Account is required",
                                }}
                                render={({ field }) => (
                                  <select
                                    {...field}
                                    className="form-control"
                                    defaultValue=""
                                    onChange={(e) => {
                                      filterSymbols(e.target.value);
                                      setAccount(e.target.value);
                                      field.onChange(e);
                                    }}
                                  >
                                    <option value="" disabled>
                                      Select...
                                    </option>
                                    {dropList?.setting?.map((setting) => {
                                      const nestedKey = Object.keys(setting)[0];
                                      const keys = Object.keys(
                                        setting[nestedKey]
                                      );
                                      return keys.map((key, index) => (
                                        <option key={index} value={key}>
                                          {key}
                                        </option>
                                      ));
                                    })}
                                  </select>
                                )}
                              />
                              <div
                                className="validationAlert"
                                style={{ margin: "10px 0px" }}
                              >
                                {errors?.accountName?.message}
                              </div>
                            </div>
                          </div>

                          <div className="row col-md-12">
                            <div className="form-group col-md-12">
                              <label>Exchange*</label>
                              <Controller
                                name="exchange"
                                control={control}
                                rules={{
                                  required: "Exchange is required",
                                }}
                                render={({ field }) => (
                                  <select
                                    {...field}
                                    className="form-control"
                                    defaultValue=""
                                    onChange={(e) => {
                                      field.onChange(e);
                                    }}
                                  >
                                    <option value="" disabled>
                                      Select...
                                    </option>
                                    {dropList?.exchange?.map((data, index) => (
                                      <option key={index}>{data}</option>
                                    ))}
                                  </select>
                                )}
                              />
                              <div
                                className="validationAlert"
                                style={{ margin: "10px 0px" }}
                              >
                                {errors?.exchange?.message}
                              </div>
                            </div>
                          </div>

                          <div className="row col-md-12">
                            <div className="form-group col-md-12">
                              <label>Product*</label>
                              <Controller
                                name="product"
                                control={control}
                                rules={{ required: "Product is required" }}
                                render={({ field }) => (
                                  <select
                                    {...field}
                                    className="form-control"
                                    defaultValue=""
                                    onChange={(e) => {
                                      field.onChange(e);
                                    }}
                                  >
                                    <option value="" disabled>
                                      Select...
                                    </option>
                                    {product?.map((data, index) => (
                                      <option key={index}>
                                        {Object.values(data).join(", ")}
                                      </option>
                                    ))}
                                  </select>
                                )}
                              />
                              <div
                                className="validationAlert"
                                style={{ margin: "10px 0px" }}
                              >
                                {errors?.product?.message}
                              </div>
                            </div>
                          </div>

                          <div className="row col-md-12">
                            <div className="form-group col-md-12">
                              <label>Symbol Full Name*</label>
                              <Controller
                                name="companyName"
                                control={control}
                                rules={{
                                  required: "Symbol full name is required",
                                }}
                                render={({ field }) => (
                                  <Autocomplete
                                    fullWidth
                                    disablePortal
                                    id="combo-box-demo"
                                    {...field}
                                    filterOptions={filterOptions}
                                    value={
                                      filterData?.find(
                                        (option) =>
                                          option?.companyName === watchSymbol
                                      ) || null
                                    }
                                    options={filterData ? filterData : []}
                                    onChange={(event, value) => {
                                      setLotSize(value?.LotSize);
                                      setSetectedSymbol(value || "");
                                      field.onChange(value?.companyName || ""); // Update the form data object with the selected value
                                    }}
                                    getOptionLabel={(option) =>
                                      option?.companyName || ""
                                    }
                                    renderOption={(props, option) => (
                                      <div
                                        {...props}
                                        key={option?.id}
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          width: "100%",
                                        }}
                                      >
                                        <div
                                          style={{
                                            float: "left",
                                          }}
                                        >
                                          <div className="symbolFont">
                                            {option?.shortName?.toString()}
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            float: "right",
                                          }}
                                        >
                                          <div className="mr-2 companyNameFont">
                                            {option?.companyName?.toString()}
                                          </div>
                                          <div className="pt-1">
                                            <span
                                              className={
                                                option?.marketType?.toString() ===
                                                  "NSE"
                                                  ? "badge badge-secondary nseBadge symbolFont"
                                                  : "badge badge-secondary bseBadge symbolFont"
                                              }
                                            >
                                              {option?.marketType?.toString()}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                  />
                                )}
                              />
                              {errors?.companyName && (
                                <div
                                  className="validationAlert"
                                  style={{ margin: "10px 0px" }}
                                >
                                  {errors?.companyName?.message}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row col-md-12">
                            <div className="form-group col-md-6">
                              <label>Symbol*</label>
                              <input
                                type="name"
                                name="symbol"
                                className="form-control"
                                placeholder="Symbol"
                                value={selectedSymbol?.shortName}
                                disabled={true}
                                {...register("symbol", {})}
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label>Expiry Date</label>
                              <input
                                type="name"
                                name="expiryDate"
                                className="form-control"
                                placeholder="Expiry Date"
                                value={selectedSymbol?.expiryDate}
                                disabled={true}
                                {...register("expiryDate", {})}
                              />
                            </div>
                          </div>

                          <div className="row col-md-12">
                            <div className="form-group col-md-6">
                              <label>Buy Price*</label>
                              <input
                                // type="number"
                                className="form-control"
                                placeholder="Buy Price"
                                {...register("buyPrice", {
                                  required: "Buy price is required",
                                  maxLength: {
                                    value: 100,
                                  },
                                })}
                              />
                              {errors?.buyPrice && (
                                <div
                                  className="validationAlert"
                                  style={{ margin: "10px 0px" }}
                                >
                                  {errors?.buyPrice?.message}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label>Buy Status*</label>
                              <Controller
                                name="buyStatus"
                                control={control}
                                rules={{
                                  required: "Buy status is required",
                                }}
                                render={({ field }) => (
                                  <>
                                    <select {...field} className="form-control">
                                      <option>Done</option>
                                      <option>Pending</option>
                                    </select>
                                    {field.touched &&
                                      field.value === "Enabled" && (
                                        <div
                                          className="validationAlert"
                                          style={{ margin: "10px 0px" }}
                                        >
                                          {errors?.buyStatus?.message}
                                        </div>
                                      )}
                                  </>
                                )}
                              />
                            </div>
                          </div>

                          <div className="row col-md-12">
                            <div className="form-group col-md-6">
                              <label>Sell Price*</label>
                              <input
                                // type="number"
                                className="form-control"
                                placeholder="Sell Price"
                                {...register("sellPrice", {
                                  required: "Sell price is required",
                                  maxLength: {
                                    value: 100,
                                  },
                                })}
                              />
                              {errors?.sellPrice && (
                                <div
                                  className="validationAlert"
                                  style={{ margin: "10px 0px" }}
                                >
                                  {errors?.sellPrice?.message}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label>Sell Status*</label>
                              <Controller
                                name="sellStatus"
                                control={control}
                                rules={{
                                  required: "Sell status is required",
                                }}
                                render={({ field }) => (
                                  <>
                                    <select {...field} className="form-control">
                                      <option>Done</option>
                                      <option>Pending</option>
                                    </select>
                                    {field.touched &&
                                      field.value === "Enabled" && (
                                        <div
                                          className="validationAlert"
                                          style={{ margin: "10px 0px" }}
                                        >
                                          {errors?.sellStatus?.message}
                                        </div>
                                      )}
                                  </>
                                )}
                              />
                            </div>
                          </div>

                          <div className="row col-md-12">
                            <div className="form-group col-md-6">
                              <label>Quantity*</label>
                              {lotSize ? (
                                <label className="pl-2">
                                  (Lotsize={lotSize})
                                </label>
                              ) : null}
                              <input
                                // type="number"
                                id="quantity"
                                className="form-control"
                                placeholder="Quantity"
                                {...register("quantity", {
                                  required: "Quantity is required",
                                  maxLength: {
                                    value: 100,
                                  },
                                })}
                              />
                              {errors?.quantity && (
                                <div
                                  className="validationAlert"
                                  style={{ margin: "10px 0px" }}
                                >
                                  {errors?.quantity?.message}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label>Status*</label>
                              <Controller
                                name="status"
                                control={control}
                                rules={{ required: "Status is required" }}
                                render={({ field }) => (
                                  <>
                                    <select {...field} className="form-control">
                                      <option>Enabled</option>
                                      <option>Disabled</option>
                                      <option>In Progress</option>
                                    </select>
                                    {field.touched &&
                                      field.value === "Enabled" && (
                                        <div
                                          className="validationAlert"
                                          style={{ margin: "10px 0px" }}
                                        >
                                          {errors?.status?.message}
                                        </div>
                                      )}
                                  </>
                                )}
                              />
                            </div>
                            <div className="form-group col-md-12">
                              <label>Switch Type*</label>
                              <div
                                style={{
                                  display: "flax",
                                  color: "cadetblue",
                                  fontWeight: 500,
                                }}
                              >
                                <label>Buy</label>
                                <Switch
                                  name="switchType"
                                  color="default"
                                  {...register("switchType", {
                                    required: false,
                                  })}
                                  className="switchStyle"
                                />
                                <label>Sell</label>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="col-md-12 justify-content-center text-center pt-3">
                                <button
                                  type="submit"
                                  className="btn btn-primary ml-3 px-5"
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TradingCreateForm;
