import React from "react";
import { Link } from "react-router-dom";

const DataNotFound = ({btn_text, btn_path}) => {
  return (
    <div className="section_page_not_found">
      {/* <h1 className="error">404</h1> */}
      <div className="page">
        No strategies found for deletion. All strategies have already been deleted.
      </div>
      <Link className="back-home" to={btn_path}>
        {btn_text}
      </Link>
    </div>
  );
};

export default DataNotFound;
