import axiosClient from "../axios-client";


export const saveEmaStrategy = async (dispatch, data) => {
    try {
        const res = await axiosClient.post("/ema_strategy", data);
        return res?.data;
    } catch (error) {
        throw error;
    } finally {
    }
};

export const getEmaStrategies = async (dispatch) => {
    try {
        const res = await axiosClient.get("/get_ema_strategies");
        return res?.data;
    } catch (error) {
        throw error;
    } finally {
    }
};

export const getEmaStrategyOrders = async (strategy_id) => {
    try {
        const res = await axiosClient.get(`/get_ema_strategy_orders/${strategy_id}`);
        return res?.data;
    } catch (error) {
        throw error;
    } finally {
    }
};

export const getEmaStrategyByID = async (dispatch, strategy_id) => {
    try {
        const res = await axiosClient.get(`/get_ema_strategy/${strategy_id}`);
        return res?.data;
    } catch (error) {
        throw error;
    } finally {
    }
};

export const updateEmaStrategy = async (dispatch, strategy_id, data) => {
    try {
        const res = await axiosClient.put(`/ema_strategy/${strategy_id}`, data);
        return res?.data;
    } catch (error) {
        throw error;
    } finally {
    }
};

export const deleteEmaStrategy = async (dispatch, strategy_id) => {
    try {
        const res = await axiosClient.delete(`/ema_strategy/${strategy_id}`);
        return res?.data;
    } catch (error) {
        throw error;
    } finally {
    }
};


export const startEmaStrategy = async (dispatch, strategy_id) => {
    try {
        const res = await axiosClient.get(`/start_ema_Strategy/${strategy_id}`);
        return res?.data;
    } catch (error) {
        throw error;
    } finally {
    }
};


export const updateEmaStrategyExpiryDate = async (strategy_id, expiry_date) => {
    try {
        const res = await axiosClient.put(`/update_ema_strategy_expiry/${strategy_id}`, expiry_date);
        return res?.data?.message;
    } catch (error) {
        throw error;
    } finally {
    }
};


export const updateEmaStrategyStatus = async (strategy_id, status) => {
    try {
        const res = await axiosClient.put(`/update_ema_strategy_script_status/${strategy_id}/${status}`);
        return res;
    } catch (error) {
        throw error;
    } finally {
    }
};


export const getDeletedEmaStrategies = async (dispatch) => {
    try {
        const res = await axiosClient.get("/deleted_strategies");
        return res?.data;
    } catch (error) {
        throw error;
    } finally {
    }
};


export const hardDeleteStrategy = async (data) => {
    try {
        const res = await axiosClient.post("/hard_delete_strategy", data);
        return res?.data;
    } catch (error) {
        throw error;
    } finally {
    }
};





// ========================================= EMA BACKTEST APIS ==============================================

export const saveEmaBacktestStrategy = async (dispatch, data) => {
    try {
        const res = await axiosClient.post("/ema_backtest_strategy", data);
        return res?.data;
    } catch (error) {
        throw error;
    } finally {
    }
};


export const getEmaBacktestStrategy = async (dispatch) => {
    try {
        const res = await axiosClient.get("/get_ema_backtest_strategy");
        return res?.data?.data;
    } catch (error) {
        throw error;
    } finally {
    }
};